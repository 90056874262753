<template>
    <section class="section">
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-form class="mt-15" @submit.prevent="handleSubmit(onSubmit)">
                <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="90vh">
                    <v-container>
                        <v-row class="mt-10">
                            <v-col cols="12" md="6">
                                <ValidationProvider :name="$t('Form.Name')" rules="required" v-slot="{ errors }">
                                    <v-text-field
                                        :error="errors[0] ? true : false"
                                        v-model="form.name"
                                        :disabled="isDisabled"
                                        :label="$t('Form.Name')"
                                        required
                                        filled
                                        hide-details="auto"
                                        name="name"
                                        shaped></v-text-field>
                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <ValidationProvider :name="$t('Form.TransportManagers')" v-slot="{ errors }">
                                    <v-select
                                        :error="errors[0] ? true : false"
                                        hide-details="auto"
                                        :items="managers"
                                        :item-text="'name'"
                                        :item-value="'id'"
                                        :disabled="isDisabled"
                                        filled
                                        attach
                                        v-model="form.agency"
                                        :label="$t('Form.TransportManagers')"></v-select>
                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <ValidationProvider :name="$t('Type')" rules="required" v-slot="{ errors }">
                                    <v-select
                                        :error="errors[0] ? true : false"
                                        hide-details="auto"
                                        :items="transportTypes"
                                        :item-text="'name'"
                                        :item-value="'value'"
                                        :disabled="isDisabled"
                                        v-model="form.type"
                                        filled
                                        attach
                                        :label="$t('Type')"></v-select>
                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <ValidationProvider :name="$t('TotalLotation')" rules="required" v-slot="{ errors }">
                                    <v-text-field
                                        :error="errors[0] ? true : false"
                                        :disabled="isDisabled"
                                        :label="$t('TotalLotation')"
                                        required
                                        filled
                                        hide-details="auto"
                                        name="name"
                                        shaped
                                        type="number"
                                        v-model="form.totalCapacity"></v-text-field>
                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <ValidationProvider
                                    :name="$t('HandicappedLotation')"
                                    rules="required"
                                    v-slot="{ errors }">
                                    <v-text-field
                                        :error="errors[0] ? true : false"
                                        :disabled="isDisabled"
                                        :label="$t('HandicappedLotation')"
                                        required
                                        filled
                                        hide-details="auto"
                                        name="name"
                                        shaped
                                        type="number"
                                        v-model="form.handicappedCapacity"></v-text-field>
                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <ValidationProvider :name="$t('StandingLotation')" rules="required" v-slot="{ errors }">
                                    <v-text-field
                                        :error="errors[0] ? true : false"
                                        :disabled="isDisabled"
                                        :label="$t('StandingLotation')"
                                        required
                                        filled
                                        hide-details="auto"
                                        name="name"
                                        shaped
                                        type="number"
                                        v-model="form.standingCapacity"></v-text-field>
                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row class="mt-8" v-if="!addTransport">
                            <v-col cols="12">
                                <p
                                    class="text-h7 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                                    {{ $t('TransportRoutes') }}
                                </p>
                            </v-col>
                            <v-col cols="2" v-for="route in transportRoutes" :key="route.id">
                                <v-chip large class="v-chip-light-bg primary--text px-6">
                                    {{ route.short_name }}
                                </v-chip>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-app-bar
                        height="80"
                        absolute
                        color="white"
                        elevate-on-scroll
                        scroll-target="#scrolling-techniques-7">
                        <v-btn @click="$router.go(-1)" class="square-button mx-3" color="grey-light">
                            <v-icon large color="grey">
                                {{ mdiChevronLeft }}
                            </v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="!addTransport">
                            <p
                                class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                                {{ $t('TransportProfile') }}
                            </p>
                            <p
                                class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                                {{ $t('TransportProfile') }}
                            </p>
                        </v-toolbar-title>
                        <v-toolbar-title v-else>
                            <p
                                class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                                {{ $t('CreateTransport') }}
                            </p>
                            <p
                                class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                                {{ $t('CreateTransport') }}
                            </p>
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <div v-if="!addTransport">
                            <v-btn type="submit" v-show="!isDisabled" :loading="isLoading" class="mx-3" color="primary">
                                <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                                    {{ mdiCheck }}
                                </v-icon>
                                <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="ehite">
                                    {{ mdiCheck }}
                                </v-icon>
                                <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Save') }}</span>
                            </v-btn>
                            <v-btn v-show="!isDisabled" @click="isDisabled = true" outlined class="mx-3" color="error">
                                <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                    {{ mdiClose }}
                                </v-icon>
                                <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                    {{ mdiClose }}
                                </v-icon>
                                <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Cancel') }}</span>
                            </v-btn>
                            <v-btn v-show="isDisabled" @click="editInformation" class="mx-3" color="grey-light">
                                <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                    {{ mdiFileDocumentEditOutline }}
                                </v-icon>
                                <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                    {{ mdiFileDocumentEditOutline }}
                                </v-icon>
                                <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Edit') }}</span>
                            </v-btn>

                            <v-dialog transition="dialog-top-transition" max-width="600">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-show="isDisabled" class="mx-3" color="grey-light" v-bind="attrs" v-on="on">
                                        <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                            {{ mdiTrashCanOutline }}
                                        </v-icon>
                                        <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                            {{ mdiTrashCanOutline }}
                                        </v-icon>
                                        <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{
                                            $t('Buttons.Delete')
                                        }}</span>
                                    </v-btn>
                                </template>
                                <template v-slot:default="dialog">
                                    <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>
                                    <v-card>
                                        <v-card-text class="text-center">
                                            <p class="title font-weight-semibold black--text mt-12">
                                                {{ $t('Alerts.Sure') }}
                                            </p>
                                        </v-card-text>
                                        <v-card-text class="text-center">
                                            <p class="body-2">
                                                {{ $t('Alerts.DeleteTransport') }}
                                                <strong class="black--text">{{ form.name }}</strong> ?<br />
                                                {{ $t('Alerts.Irreversible') }}
                                            </p>
                                        </v-card-text>

                                        <v-card-actions class="justify-end mt-4">
                                            <v-btn
                                                @click="deleteTransport"
                                                :loading="isLoading"
                                                color="error"
                                                class="px-5"
                                                >{{ $t('Buttons.Delete') }}</v-btn
                                            >
                                            <v-btn text :loading="isLoading" @click="dialog.value = false">{{
                                                $t('Buttons.Cancel')
                                            }}</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </template>
                            </v-dialog>
                        </div>
                        <v-btn v-else type="submit" :loading="isLoading" class="mx-3" color="primary">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                                {{ mdiCheck }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                                {{ mdiCheck }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Conclude') }}</span>
                        </v-btn>
                    </v-app-bar>
                </v-sheet>
            </v-form>
        </ValidationObserver>
        <v-snackbar :timeout="2000" top v-model="createSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.AddTransportSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isUpdateSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">Sucesso!</p>
            <p class="text-center black--text">{{ $t('Alerts.UpdateTransportSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isDeleteSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">Sucesso!</p>
            <p class="text-center black--text">{{ $t('Alerts.DeleteTransportSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isError" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">Erro!</p>
            <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
        </v-snackbar>
    </section>
</template>

<script>
    import {
        mdiChevronLeft,
        mdiFileDocumentEditOutline,
        mdiInformationOutline,
        mdiPlus,
        mdiTrashCanOutline,
        mdiCheck,
        mdiClose,
        mdiChevronRight,
        mdiMagnify,
    } from '@mdi/js';
    import _ from 'lodash';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    const initialState = () => ({
        isLoading: false,
        form: {
            name: '',
            type: '',
            entity: '',
            capacity: '',
            agency: '',
        },

        isLoadingTable: true,
    });

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
        },

        data() {
            return {
                ...initialState(),
                isDisabled: true,
                valid: false,
                nameRules: [
                    (v) => !!v || 'Name is required',
                    // (v) => v.length <= 10 || 'Name must be less than 10 characters',
                ],

                addTransport: false,
                managers: [],
                transportTypes: [
                    { name: '', value: 'roadway' },
                    { name: '', value: 'railway' },
                    { name: '', value: 'subway' },
                ],
                transportRoutes: [],
                isError: false,
                createSuccess: false,
                isUpdateSuccess: false,
                isDeleteSuccess: false,
            };
        },
        created() {
            this.transportTypes[0].name = this.$t('Roadway');
            this.transportTypes[1].name = this.$t('Railway');
            this.transportTypes[2].name = this.$t('Subway');

            this.form.entity = this.$store.getters['auth/getUser'].entity.id;

            if (this.$route.params.id != 'add') {
                this.addTransport = false;
                this.isDisabled = true;
                this.getTransport();
            } else {
                this.addTransport = true;
                this.isDisabled = false;
            }
            this.getAgencies();
        },

        setup() {
            return {
                mdiInformationOutline,
                mdiFileDocumentEditOutline,
                mdiCheck,
                mdiClose,
                mdiTrashCanOutline,
                mdiPlus,
                mdiChevronLeft,
                mdiChevronRight,
                mdiMagnify,
            };
        },

        methods: {
            async onSubmit() {
                if (this.addTransport) {
                    await this.onAddTransport();
                } else this.onUpdateTransport();
                setTimeout(() => {
                    this.$router.push({ name: 'TransportsList' });
                }, 1500);
            },
            editInformation() {
                this.isDisabled = !this.isDisabled;
            },

            getTransport() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-transport/fetchTransport', this.$route.params.id)
                    .then((response) => {
                        this.isLoading = false;

                        this.form = response.data.data;

                        response.data.data.trips.map((path) => {
                            //verify if object exists in array
                            if (!this.transportRoutes.some((route) => route.id === path.route.id)) {
                                this.transportRoutes.push(path.route);
                            }
                        });
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            this.form = {};
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            async onAddTransport() {
                this.isLoading = true;

                let transportData = _.assign({}, this.form); // Vai pegar todos os dados do formulário e jogar no objeto parkData (colonar objeto) é o que faz a função assing do lodash
                await this.$store
                    .dispatch('app-transport/addTransport', transportData)
                    .then(async (response) => {
                        this.createSuccess = true;
                    })

                    .catch((error) => {
                        this.isError = true;

                        if (error.response.status === 404) {
                            this.parkData = {};
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.isDisabled = true;
                    });
            },

            onUpdateTransport() {
                this.isLoading = true;

                let transportData = _.assign({}, this.form);

                transportData.name = this.form.name;
                transportData.type = this.form.type;
                transportData.totalCapacity = this.form.totalCapacity;
                transportData.handicappedCapacity = this.form.handicappedCapacity;
                transportData.standingCapacity = this.form.standingCapacity;
                transportData.agency = typeof this.form.agency == 'object' ? this.form.agency.id : this.form.agency;

                this.$store
                    .dispatch('app-transport/updateTransport', transportData)

                    .then((response) => {
                        this.isUpdateSuccess = true;
                        this.transportData = response.data.data;
                    })

                    .catch((error) => {
                        this.isError = true;

                        if (error.response.status === 404) {
                            this.transportData = {};
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.isDisabled = true;
                    });
            },

            deleteTransport() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-transport/deleteTransport', this.$route.params.id)
                    .then((response) => {
                        this.isDeleteSuccess = true;
                        setTimeout(() => {
                            this.$router.push({ name: 'TransportsList' });
                        }, 3000);
                    })
                    .catch((error) => {
                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            getAgencies() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-transport/fetchAgencies')
                    .then((response) => {
                        this.isLoading = false;
                        this.managers = response.data.data;
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            this.managers = [];
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
        },
    };
</script>
